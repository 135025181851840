/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

// import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <div
      style={{
        height: "100vh",
      }}
    >
      <main>{children}</main>
      {/* <footer>
          © {new Date().getFullYear()}, Built by
          {` `}
          <a href="https://dreamwaves.io">Dreamwaves</a>
        </footer> */}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
